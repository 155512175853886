import { getLanguageLabel } from '~/utils/getLanguageLabel';
import { CONTENTFUL_LOCALE_DOWNLOAD_LOCALE_MAP } from '~/lib/DownloadService/constants';
import type { LOCALE_CODE } from '~/lib/ContentfulService';

export function useDocumentLanguage(languageList?: ReadonlyArray<string>) {
  if (!languageList) {
    return '';
  }

  const { $resolvedLocale } = useNuxtApp();
  const logger = useLogger();

  if (languageList.length > 1) {
    return (
      getLanguageLabel(
        languageList.find(
          (language) =>
            language ===
            CONTENTFUL_LOCALE_DOWNLOAD_LOCALE_MAP[
              $resolvedLocale.value as LOCALE_CODE
            ]
        ) ?? 'en_US',
        logger
      ) +
      ' + ' +
      (languageList.length - 1)
    );
  }

  return getLanguageLabel(languageList[0], logger);
}
