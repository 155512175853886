<template>
  <ModalModule
    v-if="download"
    v-model="activeModal"
    :title="
      $textDictionary['productDocumentDownloadTab.pageTitle'] ??
      'Document Download'
    "
    close-icon-text="close"
    :is-full-screen="true"
    :esc-to-close="true"
    :click-to-close="false"
    @abort="emits('closeModal')"
    @after-close="emits('afterCloseModal')"
  >
    <div v-if="!isDownloaded" class="row">
      <div></div>
      <div class="col-lg-9">
        <h2 :class="$style.downloadName">{{ download.name }}</h2>
        <MetaList class="mb-4" :items="metaListItems" />
        <div class="rte-text mb-4" v-html="download.abstract"></div>
        <h4
          v-if="
            !hasSingleDownload &&
            !hasEmptyDownload &&
            (!download.formRequired || !downloadLocked)
          "
        >
          {{
            $textDictionary[
              'productDocumentDownloadTab.chooseLanguage.label'
            ] ?? 'Choose document language'
          }}
        </h4>
        <TabsComponent
          v-if="
            Object.keys(download.mediaLink).length > 1 &&
            (!download.formRequired || !downloadLocked)
          "
          title="title"
          tab-list-position="top"
          :tab-list-is-fullwidth="false"
        >
          <template
            v-for="linkKey of Object.keys(download.mediaLink)"
            :key="linkKey"
          >
            <TabPanel
              :title="getLanguageLabel(linkKey, logger)"
              :active-on-init="initialActiveTabMap[linkKey] ?? false"
            >
              <LinkElement
                v-if="(!downloadLocked || !formUrl) && !hasEmptyDownload"
                :link="createDownloadLink(linkKey as ContentfulLocaleCode)"
                style-type="primary"
                :target-blank="true"
                class="mt-2 mb-2"
                download
                @click="() => (isDownloaded = true)"
              >
                {{
                  $textDictionary['downloadCenter.modal.downloadLabel'] ??
                  'Download'
                }}
              </LinkElement>
            </TabPanel>
          </template>
        </TabsComponent>
        <LinkElement
          v-else-if="(!downloadLocked || !formUrl) && !hasEmptyDownload"
          :link="downloadLink"
          style-type="primary"
          :target-blank="true"
          class="mt-2 mb-2"
          download
          @click="() => (isDownloaded = true)"
        >
          {{
            $textDictionary['downloadCenter.modal.downloadLabel'] ?? 'Download'
          }}
        </LinkElement>
        <PardotFormIframe
          v-if="downloadLocked && formUrl"
          :form-url="formUrl"
          @success="handleFormSuccess"
        />
      </div>
      <div v-if="shareUrl && shareTitle" class="col-lg-3 text-end">
        <ShareButton
          :url="shareUrl"
          :title="shareTitle"
          :share-text="
            $textDictionary['downloadCenter.share.label'] ?? 'Share Download'
          "
          :copied-text="
            $textDictionary['downloadCenter.copied.label'] ??
            'Copied to Clipboard'
          "
          :copy-text="
            $textDictionary['downloadCenter.copy.label'] ?? 'Copy download link'
          "
          @click="handleOnShareClick"
        />
      </div>
    </div>
    <AlertBox
      v-if="isDownloaded"
      icon="info"
      :style="'success'"
      :text="successfulDownloadHint"
    />
  </ModalModule>
  <ModalModule
    v-else
    v-model="activeModal"
    class="rte-text"
    :title="
      $textDictionary['productDocumentDownloadTab.pageTitle'] ??
      'Document Download'
    "
    close-icon-text="close"
    :esc-to-close="true"
    :click-to-close="false"
    @abort="emits('closeModal')"
    @after-close="emits('afterCloseModal')"
  >
    <LibRichTextRenderer :rich-text="noDownloadFoundHint" />
  </ModalModule>
</template>

<script setup lang="ts">
import {
  LinkElement,
  ModalModule,
  TabsComponent,
  TabPanel,
  ShareButton,
  AlertBox,
  MetaList
} from '@hypercodestudio/basler-components';
import PardotFormIframe from '~/components/downloads/documents/PardotFormIframe.vue';
import type { DownloadEntryModel } from '~/lib/DownloadService/model';
import type { ContentfulLocaleCode } from '~/lib/ContentfulService/model';
import { getLanguageLabel } from '~/utils/getLanguageLabel';
import type { MetaItem } from '@hypercodestudio/basler-components/dist/components/elements/MetaList.vue';
import { useDocumentLanguage } from '~/lib/DownloadService/helper/useDocumentLanguage';

const {
  $resolvedLocale,
  $dictionary,
  $textDictionary,
  $globalPageSettings,
  $currentEnvName,
  $analytics
} = useNuxtApp();
const logger = useLogger();
const locale = useLocale();

interface Props {
  download?: DownloadEntryModel | null;
  open?: boolean;
  preferredLocales?: ContentfulLocaleCode[];
}

const props = defineProps<Props>();

const emits = defineEmits<{
  /**
   * Once the modal was close via the "close" button (is not triggered via "esc").
   */
  closeModal: [];
  /**
   * After the modal was closed (emits always, also while closing via "esc").
   */
  afterCloseModal: [];
}>();

const isDownloaded = ref(false);

const noDownloadFoundHint = $dictionary.value['downloadCenter.modal.notFound'];
const successfulDownloadHint =
  $textDictionary.value['downloadCenter.modal.success'];

const downloadLink = computed(
  () => Object.values(props.download?.mediaLink ?? {})[0] ?? ''
);

function createDownloadLink(locale: ContentfulLocaleCode): string {
  return props.download?.mediaLink[locale] ?? '#';
}

const shareUrl = computed(() =>
  props.download?.id
    ? buildUrlString(
        locale.value,
        `${
          $globalPageSettings.value?.documentCenterPage?.metadata?.slug ?? ''
        }/${props.download.id}`,
        undefined,
        undefined,
        true
      )
    : undefined
);
const shareTitle = computed(() => props.download?.name);

// e.g. 'https://info.baslerweb.com/l/73192/2020-02-24/98pcbf';
const formUrl = computed(() => {
  const urlString = $globalPageSettings.value?.documentsPardotFormUrl;
  if (!urlString) {
    return;
  }

  try {
    const formUrl = new URL(urlString);
    formUrl.searchParams.append('domain', $currentEnvName.value);

    const filename = props.download?.name;
    if (filename) {
      formUrl.searchParams.append('filename', filename);
    }

    return formUrl.toString();
  } catch (e) {
    logger.error('could not create new url from pardot url', e);
  }
});

const activeModal = ref(props.open);

const downloadLocked = ref(props.download?.formRequired);

const hasSingleDownload = computed(
  () => props.download && Object.keys(props.download.mediaLink).length === 1
);
const hasEmptyDownload = computed(
  () => props.download && Object.keys(props.download.mediaLink).length === 0
);

function handleOnShareClick() {
  if (props.download) {
    $analytics?.pushToDataLayer({
      event: 'share',
      ecommerce: {
        method: 'Document Center',
        content_type: props.download.downloadCategory,
        item_id: `${props.download.id} | ${props.download.name}`
      }
    });
  }
}

function handleFormSuccess() {
  downloadLocked.value = false;
}

type InitActiveTabMap = Partial<Record<string | ContentfulLocaleCode, boolean>>;

function findMatchingLocale(
  preferredLocales: ReadonlyArray<ContentfulLocaleCode> | undefined,
  existingLocales: ReadonlyArray<ContentfulLocaleCode | string>
): ContentfulLocaleCode | undefined {
  if (!preferredLocales) {
    return;
  }

  return preferredLocales.find((preferredLocale) =>
    existingLocales.includes(preferredLocale)
  );
}

/**
 * A map which contains the initial active tab for the preferred locale.
 *
 * @see https://gcp.baslerweb.com/jira/browse/DBP-1272
 */
const initialActiveTabMap = computed<InitActiveTabMap>(() => {
  if (!props.download?.mediaLink) {
    return {};
  }

  const languageActiveMap: InitActiveTabMap = Object.fromEntries(
    Object.keys(props.download.mediaLink).map((key) => [key, false])
  );

  const matchingLocale = findMatchingLocale(
    props.preferredLocales,
    Object.keys(languageActiveMap)
  );
  if (matchingLocale) {
    languageActiveMap[matchingLocale] = true;
  } else if (
    $resolvedLocale.value &&
    languageActiveMap[$resolvedLocale.value] != null
  ) {
    languageActiveMap[$resolvedLocale.value] = true;
  } else if (languageActiveMap['en-US'] != null) {
    // general fallback to en-US if available
    languageActiveMap['en-US'] = true;
  } else {
    // otherwise set the first locale active
    const firstLocale = Object.keys(languageActiveMap)[0];
    if (firstLocale) {
      languageActiveMap[firstLocale] = true;
    }
  }

  return languageActiveMap;
});

const metaListItems = computed((): MetaItem[] => {
  const items: MetaItem[] = [];
  if (props.download?.dateYear) {
    items.push({
      text: props.download?.dateYear,
      iconName: 'calendar'
    });
  }

  if (props.download?.fileExtension && props.download.readableFilesize) {
    items.push({
      text:
        props.download?.fileExtension + ', ' + props.download.readableFilesize,
      iconName: 'file'
    });
  }

  if (props.download?.allowedLanguages) {
    items.push({
      iconName: 'globe',
      text: useDocumentLanguage(props.download.allowedLanguages)
    });
  }

  if (props.download?.documentNumber) {
    items.push({
      text: props.download?.documentNumber,
      iconName: 'hashtag'
    });
  }

  return items;
});

watch(
  () => props.download?.formRequired,
  () => {
    if (props.download) {
      downloadLocked.value = props.download.formRequired;
    }
  }
);

watch(
  () => props.open,
  () => {
    activeModal.value = props.open;

    if (!props.open) {
      // reset download state on close
      isDownloaded.value = false;
    }
  }
);
</script>

<style module>
.downloadName {
  hyphens: auto;
}
</style>
